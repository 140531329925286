$env: qa;
@import '@/styles/_component-base.styles.scss';

.reviewSection {
    margin: 38px 0;
    overflow: hidden;

    @include breakpoint(lg) {
        margin: 50px 0;
    }

    &Title{
        color: color(text, headingDark);
    }

    .reviewSlider {
        overflow: initial;
    
        @include only-breakpoint(md) {
            li[class*="active"]:nth-child(odd) {
                .reviewCard {
                    background-color: white;
                    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}

.viewMoreReviewsBtn{
    text-align: center;
    background-color: color(accent, blue);
    border-color: color(accent, blue);
    margin-bottom: spacer(base);

    &:hover {
        background-color: color(accent, blue);
        border-color: color(accent, blue);;
        opacity: 0.85;
    }
}

.reviewModal {
    max-width: 682px;
}

.reviewModalContent {
    max-width: none;
    margin: 0;
    padding: 0;
}

.dialogClose {
    position: absolute;
    cursor: pointer;
    z-index: 4001;
    top: calc(spacer(4xl)/2);
    right: calc(spacer(4xl)/2);
    transform: translate(50%, -50%);
    width: spacer(md);
    height: spacer(md);

    svg {
        stroke: color(text, headingDark)
    }
}

.reviewCard {
    min-height: 367px;
    margin-top: spacer(base);
    margin-bottom: 0;
    padding-bottom: 14px;

    @include between-breakpoint(xs, md) {
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
    }

    @include breakpoint(md) {
        min-height: 377px;
        margin-top: spacer(2xl);
        margin-bottom: spacer(xs);
        padding-bottom: spacer(lg);
        padding-top: spacer(lg);
    }

    @include breakpoint(lg) {
        margin-top: spacer(2xl);
        margin-bottom: spacer(lg);
    }
}
